<template>
  <div style="background: #fcfcfc; height: 100%">
    <div style="height: 1.1053rem"></div>
    <!-- 导航 -->
    <van-nav-bar :title="shopName" left-arrow @click-left="onClickLeft" />
    <!-- 店 -->
    <div class="shop">
      <img style="width: 1.5854rem; height: 1.5854rem" :src="shopImg" />
      <div class="real-shop">
        <span style="margin: 0.08rem 0; font-weight: bold">{{ shopName }}</span>
        <van-button class="btn" round type="info" size="small">
          <span style="font-size: 0.3902rem">{{ shopDesc }}</span>
        </van-button>
      </div>
      <div class="collect-icon">
        <van-icon
          v-if="collectId == null|| collectId == '' "
          style="margin-left: 0.2439rem"
          name="star-o"
          size="0.6098rem"
          @click="likeBtn"
        />
        <van-icon
          v-else
          style="margin-left: 0.225rem"
          name="star"
          color="#fb2b53"
          size="0.6098rem"
          @click="likeBtn"
        />
        <span style="font-size: 0.3902rem; margin-left: 0.122rem">{{
          collectId == null || collectId == "" ? "收藏" : "已收藏"
        }}</span>
      </div>
    </div>
    <!-- 猜你喜欢 -->
    <div style="margin-top: 0.4878rem; padding: 0.2439rem 0.4878rem">
      <img
        style="width: 100%; height: 1rem"
        src="../../assets/img/youLike.png"
        alt
      />
    </div>
    <!-- 商品 -->
    <div class="shop-items">
      <div
        class="item"
        @click="goItemDetail(item)"
        v-for="(item, index) in shopData"
        :key="index"
      >
        <div class="_item">
          <div class="item-img">
            <img
              style="width: 100%; height: 3.2927rem"
              :src="item.picUrls[0]"
            />
          </div>
          <span style="margin-top: 0.2927rem; font-size: 0.2902rem">{{
            item.name
          }}</span>
          <div class="price-content">
            <!--   <span class="haveSold">已售{{ item.saleNum.length> 6 ? item.saleNum.substr(0, 4) : item.saleNum }}万</span> -->
            <span>
              <span class="cMoney">￥</span>
              <span class="hMoney">{{ item.priceDown }}</span>
              <span class="old-price" style="margin-left: 3px"
                >￥{{ item.priceUp }}</span
              >
            </span>
            <span>
              <span class="haveSold">已售{{ item.saleNum }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getShopDetail, clickLike } from "@/api/shopDetail2";
// import { Toast } from 'vant';
export default {
  data() {
    return {
      allData: {},
      shopData: [],
      shopName: "",
      shopImg: "",
      collectId: "", //是否收藏
      shopDesc: "",
    };
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session || '$2a$10$1VcjWPhmyHOx2i0CvutR/OhWvQpRzMK27jsXWRkQUWWY7pRm.fe.2');
    this.getShopDetail();
  },
  methods: {
    // 去商品详情页
    goItemDetail(item) {
      this.$router.push({
        path: "/itemDetail",
        query: {
          id: item.id,
          first: 0,
          session: this.$route.query.session
        },
      });
      if (localStorage.getItem("chooseSpu")) {
        localStorage.removeItem("chooseSpu")
      }
    },
    // 点击收藏
    likeBtn() {
      if(!this.$route.query.session){
        this.Login();
        return;
      }
      // 为null没有收藏
      let params = {
        type: '2',
        relationId: this.allData.id,
      }
      if (this.collectId == "" || this.collectId == null) {
        //点击收藏
        params.source = "1";
        clickLike(params).then((res) => {
          if (res.data.code === 0) {
            console.log(res);
            localStorage.setItem("id", res.data.data.id);
            this.getShopDetail();
          }
        });
      } else {
        //取消收藏
        params.id = localStorage.getItem("id");
        clickLike(params).then((res) => {
          if (res.data.code === 0) {
            console.log(res);
            localStorage.removeItem("id");
            this.getShopDetail();
          }
        });
      }
    },
    // 商品详细信息
    getShopDetail() {
      let id = this.$route.query.id || "9648447d13c84cafb1645dcabb1a1e10"; // db1f01a7a20842448201ece8cc7717c6 9648447d13c84cafb1645dcabb1a1e10
      // let id = "07553634310f43f4855c6560fe925b09";
      getShopDetail(id).then((res) => {
        if (res.data.code === 0) {
          this.allData = res.data.data;
          this.shopData = res.data.data.listGoodsSpu;
          this.shopName = res.data.data.name;
          this.shopImg = res.data.data.imgUrl;
          this.collectId = res.data.data.collectId;
          this.shopDesc = res.data.data.detail;
          console.log(this.collectId)
        } else {
          this.$toast("接口异常");
        }
      });
    },
    // rem
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    // 导航返回
    onClickLeft() {
      if(this.$route.query.first == 0) {
         this.$router.back(-1)
      } else{
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
    },
    //登录
    Login() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("login");
      }
      if (isAndroid) {
        window.android.login();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  position: fixed;
  height: 1.122rem;
  line-height: 0.5366rem;
  width: 100%;
  top: 0;
}
.van-nav-bar__content {
  height: 1.122rem;
}
/deep/ .van-nav-bar__content {
  height: 1.122rem;
}
.shop {
  margin: 0.3659rem;
  // margin-top: 1.2195rem;
  // margin-top: 0.4625rem;
  display: flex;
  align-items: center;
  .real-shop {
    margin-left: 0.2439rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    span {
      color: #333333;
      // background-color: #fcfcfc;
      font-family: bold;
      font-size: 0.439rem;
    }
    .btn {
      width: 2.9268rem;
      margin-bottom: 0.1463rem;
      background-color: #f1f1f1;
      border: none;
    }
  }
  .collect-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.shop-items {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 47%;
    // height: 5.6098rem;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 0.2439rem;
    margin: 0.2439rem 0.122rem;
    padding: 0.1951rem;
    ._item {
      // background-color: pink;
      border-radius: 0.2439rem;
      height: 100%;
      .item-img {
        width: 100%;
        height: 3.2927rem;
        border-radius: 0.2439rem;
        overflow: hidden;
        margin-bottom: 0.1951rem;
      }
      .price-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.122rem;
        .cMoney {
          color: #fb2b53;
        }
        .hMoney {
          font-size: 0.3659rem;
          color: #fb2b53;
          // margin-right: 0.2439rem;
        }
        .old {
          color: #999999;
          margin-right: 0.0732rem;
        }
        .old-price {
          color: #999999;
          text-decoration: line-through;
          font-size: 0.3171rem;
        }
        .coupon {
          color: #fb2b53;
        }
        .haveSold {
          color: #999999;
          // margin-right: 0.122rem;
          font-size: 0.3171rem;
        }
      }
    }
  }
}
/deep/ .van-nav-bar__title {
  font-size: 0.3902rem;
}
.van-button--small {
  height: 0.7805rem;
  padding: 0 0.1951rem;
}
</style>
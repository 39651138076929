import { fetchGet, fetchPost } from '../../router/https'
// 最上面梦的类型
function getShopDetail(data) {
    return fetchGet("/mallapp/shopinfo/"+ data)
}
// 点击收藏
function clickLike(data) {
    return fetchPost("/mallapp/usercollect/saveCollect", data)
}

export {
    getShopDetail,
    clickLike,
    
} 